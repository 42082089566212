import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';

// -------- Types -------->

export type CountBubbleProps = Classable & HasChildren & {
  count: number | string;
  label?: string;
}

export const CountBubbleDefaultProps: Omit<CountBubbleProps, 'count'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: #12211799;
    color: ${theme.COLOR('light')};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: ${theme.UNIT(2)} ${theme.UNIT(6)};
  `,
  tablet: css`
    border-radius: 400px;
    padding: ${theme.UNIT(6)};
  `,
  desktop: css`
  `,

});

const CountStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('link-hover')};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(12)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(20)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(24)};
  `
});

const LabelStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    margin: 0;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
  `,
});

const DividerStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG('light')};
    display: block;
    height: 4px;
    margin: ${theme.UNIT(1.5)} 0;
    width: 30px;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Count: styled.div`${CountStyles}`,
    Divider: styled.div`${DividerStyles}`,
    Label: styled.h2`${LabelStyles}`,
};

export const CountBubble: React.FC<CountBubbleProps> = (props) => {
  const { className, count, label, children } = props;
  return (
    <My.Container className={className}>
      <My.Count>{count}</My.Count>
      <My.Divider />
      <My.Label>{label || children}</My.Label>
    </My.Container>
  )
};

CountBubble.defaultProps = CountBubbleDefaultProps;