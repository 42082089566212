import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BackToTopButton, ContentEntityContent, HorizontalScroll, SliceJumpLinks, useEntity } from '@shapeable/ui';
import { filter, map, get } from 'lodash';
import { AQUA_BLUE, BRAND_1, DARK_BLUE_OVERLAY, MEDIUM_BLUE, OCEAN_BLUE, OCEAN_BLUE_OVERLAY, OCEAN_TIEL, SECONDARY_BLUE } from '../../theme';
import { FAQ_BANNER } from '../../data';
import { classNames } from '@shapeable/utils';
import { EntityTitleHeader } from '@shapeable/web';

const cls = classNames('page-layout-faq');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    [id] {
      scroll-margin-top: 150px;
    }

    height: 100%;
  `
});

const ContentStyles = breakpoints({
  base: css`
    
  `,
});

const JumpLinksSectionStyles = breakpoints({
  base: css`
    position: sticky;
    top: 60px;
    z-index: 10;
    padding-top: 0;
  `,
});

const JumpLinksScrollStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const JumpLinksStyles = breakpoints({
  base: css`
    

    font-size: ${theme.FONT_SIZE(18)};
    padding: ${theme.UNIT(2)};
    justify-self: center;

    li {
      padding-left: ${theme.UNIT(6)};
      padding-right: ${theme.UNIT(6)};
    }

    li:first-child {
      padding-left: ${theme.UNIT(1)};
    }

    li:last-child {
      background: none;
    }
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
  `
});

const FooterStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.UNIT(8)};
  `,
});

const BackToTopButtonStyles = breakpoints({
  base: css`
    justify-self: center;
    width: 100%;
    max-width: 280px;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Content: styled(ContentEntityContent)`${ContentStyles}`,
    
    JumpLinksSection: styled.div`${JumpLinksSectionStyles}`,
      JumpLinksScroll: styled(HorizontalScroll)`${JumpLinksScrollStyles}`,
      JumpLinks: styled(SliceJumpLinks)`${JumpLinksStyles}`,

    Footer: styled.div`${FooterStyles}`,
      BackToTopButton: styled(BackToTopButton)`${BackToTopButtonStyles}`,
};

export const PageLayoutFaq: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const jumpSlices = map(filter(entity.slices, slice => {
    const slug = get(slice, 'layout.slug');
    return slug === 'header' || slug === 'featured-organisations';
    }), slice => {
    // shorten names for jump links
    const name = slice.name;
    return {
      ...slice,
      name,
      title: name
    };
  });

  return (
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader entity={entity} overlayColor={OCEAN_BLUE_OVERLAY} backgroundImage={FAQ_BANNER} />

        <My.JumpLinksSection style={{ backgroundColor: OCEAN_TIEL}}>
          <My.JumpLinksScroll>
          <My.JumpLinks items={jumpSlices} />
          </My.JumpLinksScroll>
        </My.JumpLinksSection> 

      {slices}
      <My.Footer>
        <My.BackToTopButton />
      </My.Footer>

    </My.Container>
  )
};