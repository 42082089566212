import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PagesProvider as Provider, platformProviderValue } from '@shapeable/ui';
import { sortBy } from 'lodash';

const PAGES_QUERY = graphql`
  query PlatformPagesQuery {
    platform {
      allPages { 
        id path slug name __typename _schema { label pluralLabel } 
        type { id name }
        intro { id text }
        summary { id text }
        openGraph { image { id thumbnails { full { id url } } } }
        feedEntries { id name slug __typename _schema { label pluralLabel } }
        methodologies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        genera { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        species { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
      }
    }
  }`;

export const PagesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(PAGES_QUERY), 'allPages' ), 'name') }>{children}</Provider>;
}