import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, CellLabel, useLang, EntityTextValueList, EntityValue, EntityCard } from '@shapeable/ui';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';

// -------- Types -------->

export type RestorationProjectCardProps = Classable & HasChildren & { 
  entity?: RestorationProject;
};

export const RestorationProjectCardDefaultProps: Omit<RestorationProjectCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type CardProps = {

}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: ${theme.COLOR('text')};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    margin-top: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(3)};
  `,
});

const LinkedStyles = breakpoints({
  base: css`
    line-height: 1.2em;
    margin-top: ${theme.UNIT(2)};
    font-size: ${theme.FONT_SIZE(14)};
    &:first-child {
      margin-top: ${theme.UNIT(1)};
    }
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  Card: styled(EntityCard)<CardProps>`${CardStyles}`,
  Body: styled.div`${BodyStyles}`,
  Linked: styled(EntityTextValueList)`${LinkedStyles}`,
  Organisation: styled(EntityValue)`${OrganisationStyles}`,
};

export const RestorationProjectCard: React.FC<RestorationProjectCardProps> = (props) => {
  const { children, ...rest } = props;
  const entity = useEntity(props.entity);
  
  const { methodologies = [], reasons = [], species = [], organisation } = entity;

  const hasMethodologies = !!methodologies.length;
  const hasReasons = !!reasons.length;
  const hasSpecies = !!species.length;

  const t = useLang();

  const hasBody = hasMethodologies || hasSpecies || hasReasons;

  return (
    <My.Card {...rest}>
    {
      hasBody &&
      <My.Body>
        {
          organisation && <My.Organisation imageStyle="logo" entity={organisation} />
        }
        <My.Linked items={species} />
        <My.Linked items={methodologies} />
        <My.Linked items={reasons} />
      </My.Body>
    }
    {children}
    </My.Card>
  )
};

RestorationProjectCard.defaultProps = RestorationProjectCardDefaultProps;