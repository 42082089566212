import { ContentEntityIntro, useEntity } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import {  } from '@shapeable/web';
import { PledgeChart, PledgeChartProps } from '../elements/pledge-chart';
import { OCEAN_BLUE, CONTENT_BACKGROUND, SECONDARY_BLUE } from '../../theme';
import { classNames } from '@shapeable/utils';

const cls = classNames('slice-pledge-chart');

// -------- Types -------->

export type SlicePledgeChartProps = Classable & HasChildren & PledgeChartProps & { 
  entity?: Slice;
};

export const SlicePledgeChartDefaultProps: Omit<SlicePledgeChartProps, 'entity' | 'currentValue' | 'firstTarget' | 'finalTarget'> = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(13)};
    padding: ${theme.UNIT(3)} 0;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(14)};
  `,
  desktop: css`
    padding: ${theme.UNIT(6)} 0 0;
    font-size: ${theme.FONT_SIZE(15)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(4)} 0;
    text-transform: uppercase;
    font-weight: 500;
    color: ${SECONDARY_BLUE};
    font-size: ${20/13}em;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1em;
    padding-right: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  `
});


const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    flex-shrink: 0;
  `,
  tablet: css`
    width: 30%;
  `
});

const ChartStyles = breakpoints({
  base: css`
    width: 100%;
    flex-shrink: 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(0)};
    width: 70%;
  `
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

    Layout: styled.div`${LayoutStyles}`,
      Body: styled.div`${BodyStyles}`,
        Title: styled.h3`${TitleStyles}`,
        Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      Chart: styled(PledgeChart)`${ChartStyles}`
};

export const SlicePledgeChart: React.FC<SlicePledgeChartProps> = (props) => {
  const { className, children, ...pledgeChartProps } = props;
  const entity = useEntity(props.entity);
  const { intro, title } = entity;

  return (
    <My.Container className={cls.name(className)}>
      <My.Layout>
        <My.Body>
          <My.Title>{title}</My.Title>
          <My.Intro entity={entity} />
        </My.Body>
        <My.Chart
          {...pledgeChartProps}
          backgroundColor={CONTENT_BACKGROUND}>
        </My.Chart>
      </My.Layout>
    </My.Container>
  )
};

SlicePledgeChart.defaultProps = SlicePledgeChartDefaultProps;