import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { MapLinkButton } from '../elements/map-link-button';
import { useSite } from '../../hooks/use-site';
import { EntityQuarterlyGrowthChart } from '../entities/entity-quarterly-growth-chart';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ButtonStyles = breakpoints({
  base: css`
  `,
});

const ChartStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
  Button: styled(MapLinkButton)`${ButtonStyles}`,
  Chart: styled(EntityQuarterlyGrowthChart)`${ChartStyles}`,

};

export const BlockLayoutOrganisationTimelineChart: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();
  const { metrics } = useSite();
  const { countOrganisationsByQuarter } = metrics;


  return (
    <My.Container 
      showContent 
      entity={entity} 
      className={className}
      controls={
        <My.Button entityTypeNames={["Organisation"]} href='#map'>{t('View Organisations On Map')}</My.Button>
      }
    >
      <My.Chart 
        valueLabel='# Organisations'
        items={countOrganisationsByQuarter} 
      />
    </My.Container>
  )
};