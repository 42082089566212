import React from 'react';
import { LinearNavigationProvider as CoreProvider, useLinearMenu } from "@shapeable/ui"
import { Menu } from '@shapeable/types';

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {

  const { items } = useLinearMenu() as { items: Menu[] } || { items: [] } as { items: Menu[] };
  
  return (
    <CoreProvider value={items}>
    {children}
    </CoreProvider>
  )
}