import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, CitationCard as CoreCitationCard, CitationCardProps as CoreCitationCardProps, EntityList, EntityListSection } from '@shapeable/ui';

import { Citation } from '@shapeable/kelp-forest-alliance-types';

// -------- Types -------->

export type CitationCardProps = Omit<CoreCitationCardProps, 'entity'> & { 
  entity: Citation
};

export const CitationCardDefaultProps: Omit<CitationCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
    a {
      text-decoration: none;
      color: ${theme.COLOR('link')};

      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    }

    .shp--card__content {
      padding: ${theme.UNIT(5)};
      border: 2px dotted ${theme.COLOR('line-mid')};
      background: #FFF;
      border-radius: 0 40px 0 0;
      line-height: 1.3em;

      > .shp--cell-header > .shp--cell-header__label-and-badge > .shp--cell-label {
        line-height: 1.8em;
      }

      .shp--citation-cell__number {
        display: none;
      }

      .shp--more-divider {
        margin-top: 0;
      }
    }

    
  `,
});

const RestorationProjectsStyles = breakpoints({
  base: css`
    padding: 0;
    margin-top: ${theme.UNIT(5)};
    padding-top: ${theme.UNIT(5)};
    border-top: 1px solid ${theme.COLOR('line-mid')};
    h1 {
      color: ${theme.COLOR('text')};
    }
  `,
});


// -------- Components -------->

const My = {
  Container: styled(CoreCitationCard)<ContainerProps>`${ContainerStyles}`,
  RestorationProjects: styled(EntityListSection)`${RestorationProjectsStyles}`
};

export const CitationCard: React.FC<CitationCardProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);

  return (
    <My.Container {...rest} linkVariant="title" className={className}>
      <My.RestorationProjects 
        useLinks 
        imageSize={32}
        showLabel={false}
        title="Related Project:"
        pluralTitle="Related Projects:"
        maxItems={3} items={entity.restorationProjects} 
      />
    </My.Container>
  )
};

CitationCard.defaultProps = CitationCardDefaultProps;