import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { OrganisationsProvider as Provider, platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';

const ORGANISATIONS_QUERY = graphql`
  query PlatformOrganisationsQuery {
    platform {
      allOrganisations { 
        id slug name path latitude longitude __typename _schema { label pluralLabel } 
        description { text(truncate: { words: 50}) }
        openGraph { image { url } }
        pledges {
          id value metric { id slug name title unit { id shorthand name } }
          latitude longitude
        }
      }
    }
  }`;

export const OrganisationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(ORGANISATIONS_QUERY), 'allOrganisations' ), 'name') }>{children}</Provider>;
};
