import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { LinkButton, useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { BlockImageValueIntro } from '../entities/block-image-value-intro';
import { useSite } from '../../hooks/use-site';
import { coalesce } from '@shapeable/utils';
import { DottedChevronRightHeavyIconGlyph } from '@shapeable/icons';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    box-sizing: border-box;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
  Body: styled(BlockImageValueIntro)`${BodyStyles}`,
  Button: styled(LinkButton)`${ButtonStyles}`,
};

export const BlockLayoutCountRestorationProjects: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();
  const { metrics } = useSite();
  const { countRestorationProjects } = metrics;
  const linkHref = coalesce(entity, ['link.page.path', 'link.url']);

  return (
    <My.Container 
      entity={entity} 
      className={className}
      controls={
        <My.Button variant='slim' href={linkHref} icon={DottedChevronRightHeavyIconGlyph}>{t('View All Projects')}</My.Button>
      }
    >
      <My.Body 
        value={countRestorationProjects} 
        entity={entity}
      />
    </My.Container>
  )
};