import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';
import { OrganisationPledgesProvider as Provider } from '../../components/context/organisation-pledges.context';
const ORGANISATION_PLEDGES_QUERY = graphql`
  query PlatformOrganisationPledgesQuery {
    platform {
      allOrganisationPledges { 
        __typename _schema { label pluralLabel }
        id value metric { id slug name title unit { id name } }
        latitude longitude
        organisation { 
          pledges {
            id value 
            metric { 
              id slug name title 
              unit { 
                id shorthand name 
              } 
            }
            latitude longitude
          }
          id slug name latitude longitude __typename _schema { label pluralLabel } description { text }
          openGraph { image { url } }
        }
      }
    }
  }`;

export const OrganisationPledgesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(ORGANISATION_PLEDGES_QUERY), 'allOrganisationPledges' ), 'name') }>{children}</Provider>;
};
