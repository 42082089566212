import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntitySelect as EntitySelectCore, EntitySelectProps as CoreProps } from '@shapeable/ui';
import { SECONDARY_BLUE } from '../../theme';
import { DottedChevronDownIconGlyph } from '@shapeable/icons';

// TODO - Make this an EntityDottedSelect in web

/**
 * A styled proxy of the standard EntitySelect
 */

// -------- Types -------->

export type EntitySelectProps = Classable & HasChildren & CoreProps & { 
};

export const EntitySelectDefaultProps: Omit<EntitySelectProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const SelectStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};

    .shp--entity-select__indicator-separator {
      display: none;
    }

    .shp--entity-select__clear-indicator {
      margin-top: 0;
    }

    .shp--entity-select__control {
      border: none;
      min-height: 66px;
    }

    .shp--entity-select__indicators {
      align-items: flex-start;
      margin-top: ${theme.UNIT(6)};
    }

    .shp--entity-select__multi-value {
      background: none;
      border: none;
    }

    .shp--entity-select__placeholder {
      color: ${theme.COLOR('secondary')};
      text-transform: uppercase;
      font-weight: 400;
      padding-left: 4px;
      font-size: ${theme.FONT_SIZE(13)};
    }

    .shp--entity-select__multi-value__remove {
      margin-left: 4px;
      margin-right: 0;
    }

    ${(props: any) => props.hasError && css`
      .shp--entity-select__control {
        border-color: red;
      }
    `}
    

  `,
});

const IndicatorStyles = breakpoints({
  base: css`
    width: 28px;
    margin: 0 16px;
    ${theme.FILL(SECONDARY_BLUE)};
  `,
});

// -------- Components -------->

const My = {
  Select: styled(EntitySelectCore)<ContainerProps>`${SelectStyles}`,
  Indicator: styled(DottedChevronDownIconGlyph)`${IndicatorStyles}`,
};

export const EntitySelect: React.FC<EntitySelectProps> = (props) => {

  return (
    <My.Select
      {...props}
      isClearable={false}
      showSearchIcon={false}
      components={{
        DropdownIndicator: My.Indicator
      }}
    />
  )
};

EntitySelect.defaultProps = EntitySelectDefaultProps;