import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/web';
import { createIntroSlice } from '../../data';
import { SliceLayoutIntro } from '../slice-layouts';
import { ORANGE } from '../../theme';
import { COLOR_WHITE, useEntityOptions } from '@shapeable/ui';

// -------- Child Component Props -------->

type ContainerProps = {
  slug?: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${({ slug }: ContainerProps ) => slug === 'annual-report-2023' && css`
      .shp--slice-background-color-block__body {
        a {
          color: ${ORANGE};
          &:hover {
            filter: brightness(1.2);
          }
        }
      }
      .shp--slice-layout-key-message {
        .shp--slice-layout__boundary {
          padding: 80px 0;
        }
      }
    `}
    
    .shp--slice-layout-section .shp--slice-section-color-block__subtitle {
      font-size: 1.8em;
      padding-top: 8px;
    }
  `,
  tablet: css`
    ${({ slug }: ContainerProps ) => slug === 'annual-report-2023' && css`
      .shp--slice-layout-key-message {
        .shp--slice-layout__boundary {
          padding: 150px 0;
        }
      }
    `}
    
  `,
  desktop: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutDefault: PageLayoutComponent = (props) => {
  const { className, entity } = props;
  const { content, intro } = entity;
  const hasIntro = intro && intro.text;

  const introSlice = createIntroSlice(entity);
  
  const { lightIntro } = useEntityOptions(entity);

  if (lightIntro) {
    introSlice.backgroundColor = COLOR_WHITE;
  }

  const slices = <>
    {
      hasIntro && <SliceLayoutIntro entity={introSlice} />
    }
    {props.slices}
  </>

  return (
    <My.Container 
      headerProps={{ showSlideControls: true, aspectRatio: { base: 14 / 9, landscape: 21 / 9, desktop: 24 / 9 }, variant: 'overlay', showOverlayGradient: true }}
      slices={slices}
      className={className}
      entity={entity} 
      slug={entity.slug}
    /> 
  )
};
