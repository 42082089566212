import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/web';
import { createIntroSlice } from '../../data';
import { SliceLayoutContactForm, SliceLayoutIntro, SliceLayoutMembershipGridContact } from '../slice-layouts';
import { useEntity } from '@shapeable/ui';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)`${ContainerStyles}`,
};

export const PageLayoutContactForm: PageLayoutComponent = (props) => {
  const { className } = props;
  const entity = useEntity(props.entity);
  const { content, intro } = entity;
  const introSlice = createIntroSlice(entity);
  const hasIntro = intro && intro.text;

  const slices = <>
    {
      hasIntro && <SliceLayoutIntro entity={introSlice} />
    }
    <SliceLayoutMembershipGridContact />
    <SliceLayoutContactForm />
  </>

  return (
    <My.Container 
      headerProps={{ showSlideControls: true, aspectRatio: { base: 4 / 3, desktop: 24 / 9 }, variant: 'overlay', showOverlayGradient: true }}
      slices={slices}
      className={className}
      entity={entity} 
    /> 
  )


  // return (
  //   <My.Container className={className}>
      
  //     <My.Header entity={entity} />
  //       <My.MembershipLevels entity={SLICE_MEMBERSHIP_CONTACT} />
  //       <My.BodyBoundary maxWidth="600px">
  //         <My.Body>
  //           <My.Title>{title}</My.Title>
  //           <My.Content entity={content} />
  //           <My.Form />
  //         </My.Body>
  //       </My.BodyBoundary>
  //   </My.Container>
  // )
};