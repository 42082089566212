import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Button, useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { OCEAN_BLUE, CONTENT_BACKGROUND } from '../../theme';
import { EntityPercentagePieChart } from '../entities/entity-percentage-pie-chart';
import { useSite } from '../../hooks/use-site';
import { EntityPercentageLegend } from '../entities/entity-percentage-legend';
import { MapLinkButton } from '../elements/map-link-button';
import { DottedChevronDownIconGlyph, DottedChevronRightIconGlyph, DottedChevronUpIconGlyph } from '@shapeable/icons';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    margin-left: ${theme.UNIT(1)};
    flex-grow: 1;
  `,
  tablet: css`
  `
});

const LegendButtonStyles = breakpoints({
  base: css`
    flex-grow: 1;
    margin-right: ${theme.UNIT(1)};
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  `,
  tablet: css`
  `
});

const LegendStyles = breakpoints({
  base: css`
    position: absolute;
    height: calc(100% - 100px);
    width: 100%;
    box-sizing: border-box;
    left: 0;
    top: 40px;
    background: ${CONTENT_BACKGROUND}DD;
    padding: ${theme.UNIT(3)};
  `,
});

const ChartStyles = breakpoints({
  base: css`
    align-self: center;
    padding: ${theme.UNIT(4)} 0;
  `,
});



// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,

  Chart: styled(EntityPercentagePieChart)`${ChartStyles}`,
  Button: styled(MapLinkButton)`${ButtonStyles}`,
  LegendButton: styled(Button)`${LegendButtonStyles}`,
    Legend: styled(EntityPercentageLegend)`${LegendStyles}`,
};

export const BlockLayoutOrganisationTypeChart: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const { metrics } = useSite();
  const { organisationTypePercentages } = metrics;
  const [ legendIsShown, setLegendIsShown ] = React.useState<boolean>(false);

  const legendButtonOnClick = React.useCallback(() => {
    setLegendIsShown(!legendIsShown);
  }, [legendIsShown]);
  
  return (
    <My.Container 
      showContent 
      entity={entity} 
      className={className}
      controls={
        <>
          <My.LegendButton icon={legendIsShown ? DottedChevronDownIconGlyph : DottedChevronUpIconGlyph} variant='slim' onClick={legendButtonOnClick}>{t(legendIsShown ? 'Hide Legend' : 'View Legend')}</My.LegendButton>
          <My.Button showImage={false} entityTypeNames={["Organisation"]} variant='slim' href='#map' icon={DottedChevronRightIconGlyph}>{t('View On Map')}</My.Button>
        </>
      }
    >
      <My.Chart 
        size={200}
        items={organisationTypePercentages} 
        color={OCEAN_BLUE}
      />
      {
        legendIsShown &&
        <My.Legend baseColor={OCEAN_BLUE} items={organisationTypePercentages} />
      }
    {children}
    </My.Container>
  )
};