import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('pledge-circle');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { OCEAN_BLUE } from '../../theme';
import { Icon, useTheme } from '@shapeable/ui';
import { PledgeIcon } from '@shapeable/icons';

// -------- Types -------->

export type PledgeCircleProps = Classable & HasChildren & {
  fill?: string;
  onClick?: React.MouseEventHandler;
}

export const PledgeCircleDefaultProps: PledgeCircleProps = {
  fill: OCEAN_BLUE,
};

// -------- Child Component Props -------->

type ContainerProps = {
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    width: 44px;
    height: 44px;
  `,
});

const IconStyles = breakpoints({
  base: css`
    position: absolute;
    top: -1px;
    left: -1px;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Icon: styled(Icon)`${IconStyles}`,
};

export const PledgeCircle: React.FC<PledgeCircleProps> = (props) => {
  const { className, fill, onClick } = props;
  return (
    <My.Container>
    <PledgeIcon onClick={onClick} className={cls.name(className)} color="bright" fill={fill} size={50} />
    </My.Container>
  )
};

PledgeCircle.defaultProps = PledgeCircleDefaultProps;