import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { 
  EntitySelectSizeVariant, EntityTypeToggleItem, EntityTypeTogglePill, useEntityTypeCards, 
  EntityCard, EntityCardProps, EntitySelect, EntityFilterSelectReplaceable, 
  Card, Grid, GridProps, EntitySelectProps, EntitySelectOptionData,
} from '@shapeable/ui';
import { Classable, Dictionary, Entity, EntityTypeName, HasChildren, PlainObject, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { mapValues, pickBy, map, uniqBy, get, size, find, flatten, sortBy, filter, intersection } from 'lodash'
import { without, includes } from 'lodash';
import { sprintf } from 'sprintf-js';
import { classNames, entityPluralLabel, entityTypeNameFor, titleCase, toArray } from '@shapeable/utils';

const cls = classNames('entity-grid');

export type EntityGridFilterValues = Dictionary<EntitySelectOptionData[]>;

export type EntityGridFilterValuesChangeHandler = (values: EntityGridFilterValues) => void;

export type EntityGridFilterOptions = {
  placeholder?: string;
  entityTypeName?: EntityTypeName;
};

export type EntityGridProps = Classable & HasChildren & Omit<GridProps, 'items'> & {
  items?: Entity[];
  card?: React.FC<EntityCardProps>;
  select?: React.FC<EntitySelectProps>;
  filterValues?: EntityGridFilterValues;
  filterFieldNames?: string[];
  toggleTypes?: EntityTypeToggleItem[];
  filterFieldOptions?: Dictionary<EntityGridFilterOptions>;
  filterSize?: EntitySelectSizeVariant;
  filterSpacing?: number;
  onFilterValuesChange?: EntityGridFilterValuesChangeHandler;
  pageSize?: number;
  cardMaxWords?: number;
};

export const EntityGridDefaultProps: EntityGridProps = {
  items: [],
  filterFieldNames: [],
  toggleTypes: [],
  filterValues: {},
  filterFieldOptions: {},
  filterSpacing: 0,
  select: EntitySelect,
  onFilterValuesChange: () => {},
  desktopAutoColumns: 3,
  desktopFullHdAutoColumns: 3,
  desktopLargeAutoColumns: 3,
  tabletAutoColumns: 2,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type FiltersProps = {
  filterSize?: EntitySelectSizeVariant;
}

type FilterProps = {
  filterSize?: EntitySelectSizeVariant;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const GridStyles = breakpoints({
  base: css`
  `,
});


const CardStyles = breakpoints({
  base: css`
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    margin-top: ${theme.UNIT(1)};
    padding-bottom: ${theme.UNIT(3)};

    `,
  tablet: css`
    flex-direction: row;
    padding: 0 ${theme.UNIT(3)};
    
  `,
  last: css`
    @media (min-width: ${process.env.MAX_WIDTH_CONTENT || 1088}px) {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  desktop: css`
    ${({ filterSize }: FilterProps ) => filterSize === 'slim' && css`
      margin-bottom: ${theme.UNIT(filterSize === 'slim' ? 1 : 7)};
    `}
  `
});


const FilterStyles = breakpoints({
  base: css`  
    ${({ filterSize }: FilterProps ) => filterSize === 'slim' && css`
        padding: 0 ${theme.UNIT(3)};
      `}
    `,
  tablet: css`
    padding: 0;
  `,
});

const FilterSelectStyles = breakpoints({
  base: css`
  `,
});

const TogglesStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(2)};
    padding: 0 ${theme.UNIT(3)} ${theme.UNIT(3)} ${theme.UNIT(3)};
  `,
  desktop: css`
    padding: ${theme.UNIT(3)} 0;
  
  `,
});

const ToggleStyles = breakpoints({
  base: css`
    
  `,
});


type WithId = { id?: string, value?: string };
const ids: (items: WithId[]) => string[] = (items = []) => (items.map(item => (item.id || item.value)));

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(Grid)<FiltersProps>`${FiltersStyles}`,
      Filter: styled.div.attrs(cls.attr('filter'))<FilterProps>`${FilterStyles}`,
        FilterSelect: styled(EntityFilterSelectReplaceable)`${FilterSelectStyles}`,
    Toggles: styled.div.attrs(cls.attr('toggles'))`${TogglesStyles}`,
        Toggle: styled(EntityTypeTogglePill)`${ToggleStyles}`,
  
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(Card)`${CardStyles}`,
};

export const EntityGrid: Shapeable.FC<EntityGridProps> = (props) => {
  const { className, filterValues, items, filterFieldNames, onFilterValuesChange, cardMaxWords, filterSize, filterSpacing, toggleTypes, ...gridProps } = props;
  const allItems = props.items as (Dictionary<Entity | Entity[]>)[];

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const hasFilters = !!filterFieldNames.length;

  const hasToggles = !!toggleTypes.length;

  // console.log(allItems.map((item => entityTypeNameFor(item))))
  
  const filterItems: Dictionary<Entity[]> = useMemo(() => pickBy(filterFieldNames.reduce((acc: PlainObject, fieldName) => {
    const selectedItems = hasToggles && !!selectedTypes.length 
    ? filter(allItems, (item) => includes(selectedTypes, entityTypeNameFor(item))) 
    : allItems;

    acc[fieldName] = sortBy(uniqBy(flatten(selectedItems.map(item => (item[fieldName] || []))), 'id'), 'name') ;
    return acc;
  }, {}), filterItem => filterItem && filterItem.length), [items, selectedTypes]);


  const filterFieldOptions: Dictionary<EntityGridFilterOptions> = {
    ...props.filterFieldOptions,
    ...mapValues(filterItems, (filterItem, key) => {
      const knownEntityTypeName = filterItem.length && filterItem[0].__typename;
      const entityTypeName = (knownEntityTypeName || 'Entity') as EntityTypeName;
      const placeholder = sprintf(`(All %s)`, knownEntityTypeName ? entityPluralLabel(filterItem.length && filterItem[0]) : titleCase(key));
      return {
        placeholder,
        entityTypeName
      };
    }),
  };

  const filterValueIds = mapValues(filterValues, item => ids(item || []));
  const matchingItems = (hasFilters ? allItems.filter(item => {
    if (size(filterValues) === 0) return true;
  
    return Object.entries(filterValueIds).every(([fieldName, idSet]) => {
      const itemFieldValues = ids(toArray(item[fieldName]));
      return intersection(itemFieldValues, idSet).length > 0;
    });
  }) : allItems) as Entity[];
  

  const finalItems = hasToggles && selectedTypes.length ? filter(matchingItems, (item) => includes(selectedTypes, entityTypeNameFor(item))) : matchingItems;

  const onSelectChange = (fieldName: string) => (value: EntitySelectOptionData[]) => {
    const newValue = [...(value || [])];

    const newFilters = pickBy({
      ...filterValues,
      [fieldName]: !!newValue.length && newValue,
    });
    
    onFilterValuesChange(newFilters);
  };

  const ENTITY_CARD_COMPONENTS = useEntityTypeCards();

  return (
    <My.Container className={cls.name(className)}>
      {
        hasToggles && 
          <My.Toggles>
            {
              toggleTypes.map((type) => {
                
                const isOn = includes(selectedTypes, type.name);
                return (
                  <My.Toggle
                    key={type.name}
                    label={type.label}
                    entityTypeName={type.name as EntityTypeName}
                    isOn={isOn}
                    onClick={() => {
                      const newSelectedTypes = isOn ? without(selectedTypes, type.name) : [type.name, ...selectedTypes];
                      setSelectedTypes(newSelectedTypes);
                    }}
                  />
                )
              })
            }
          </My.Toggles>
      }
      {
        hasFilters && 
        <My.Filters filterSize={filterSize} maxColumns={size(filterItems)} spacing={filterSpacing} items={map(filterItems, (filterItem, fieldName) => {
          return (
            <My.Filter key={`filter-${fieldName}`} filterSize={filterSize}>
              <My.FilterSelect isMulti size={filterSize} value={filterValues[fieldName] || []} onChange={onSelectChange(fieldName)} placeholder={get(filterFieldOptions, `${fieldName}.placeholder`, 'Select')} entityTypeName={get(filterFieldOptions, `${fieldName}.placeholder`, 'Entity')} items={filterItem} />
            </My.Filter>
          )})} />
      }
      <My.Grid
        {...gridProps}
        items={finalItems.map(item => {
          const Card = props.card || ENTITY_CARD_COMPONENTS[entityTypeNameFor(item) as EntityTypeName] || EntityCard;
          return <Card key={item.id} maxWords={cardMaxWords} entity={item} />;
        })}
      />
    </My.Container>
  )
};

EntityGrid.defaultProps = EntityGridDefaultProps;
EntityGrid.cls = cls;