import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Dictionary, HasChildren, IconComponent, Shapeable, MenuItem } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useLink, Icon, useLang } from '@shapeable/ui';
import { includes } from 'lodash';
import { classNames } from '@shapeable/utils';

const cls = classNames('side-navigation-link');

// -------- Types -------->

export type SideNavigationLinkVariant = 'default' | 'accent' 

export const SideNavigationVerticalUnitPadding: Record<SideNavigationLinkVariant, number> = {
  default: 0,
  accent: 0,
};

export const SideNavigationMinimisedVerticalUnitPadding: Record<SideNavigationLinkVariant, number> = {
  default: 1,
  accent: 0,
};

export const SideNavigationHorizonalUnitPadding: Record<SideNavigationLinkVariant, number> = {
  default: 0,
  accent: 2,
};

export const SideNavigationMinimisedHorizonalUnitPadding: Record<SideNavigationLinkVariant, number> = {
  default: 0.5,
  accent: 0.5,
};


export type SideNavigationLinkProps = Classable & HasChildren & {
  entity: MenuItem;
  variant?: SideNavigationLinkVariant;
  isActive?: boolean;
  activeColor?: string;
  showActiveSidebar?: boolean;
  color?: string;
  barHeight?: number;
  sidebarWidth?: number;
  hoverColor?: string;
  style?: Dictionary<any>;
  onClick?: React.MouseEventHandler;
  icon?: React.ReactNode;
  iconSize?: number;
  linkStyle?: React.CSSProperties;
}

export const SideNavigationLinkDefaultProps: Omit<SideNavigationLinkProps, 'entity'> = {
  variant: 'default',
  iconSize: 12,
  sidebarWidth: 2,
  showActiveSidebar: true,
  style: {
  }
};

// -------- Child Component Props -------->

type ContainerProps = {
  variant: SideNavigationLinkVariant;
  isActive: boolean;
  _color: string;
  _activeColor: string;
  _hoverColor: string;
  sidebarWidth: number;
}

type LabelProps = {
  _color: string;
  _activeColor: string;
  _hoverColor: string;
  isActive: boolean;
  variant: SideNavigationLinkVariant;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    display: inline-block;
    transition: border 0.2s ease-in-out;

    ${({ variant, sidebarWidth, _activeColor, isActive }: ContainerProps) => isActive && variant === 'default' && css`
      border-left: ${sidebarWidth}px solid ${theme.COLOR(_activeColor)};
      padding-left: ${theme.UNIT(3)};
    `}

  `,
});


const LabelStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-size: 1em;
    display: flex;
    user-select: none;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    white-space: nowrap;

    ${({ isActive, _activeColor, _color, _hoverColor, variant }: LabelProps) => css`
      color: ${theme.COLOR(isActive ? _activeColor : _color)};
      ${theme.FILL(isActive ? _activeColor : _color)};
      &:hover {
        color: ${theme.COLOR(_hoverColor)};
        ${theme.FILL(_hoverColor)};
      }
      padding: ${theme.UNIT(SideNavigationVerticalUnitPadding[variant])} ${theme.UNIT(SideNavigationHorizonalUnitPadding[variant])};
    `}

  `,
});

const IconStyles = breakpoints({
  base: css`
    
    path, rect, circle, polygon {
      transition: fill 0.2s ease-in-out;
    }
    
    margin-left: ${theme.UNIT(3)};
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    cursor: pointer;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.span<ContainerProps>`${ContainerStyles}`,
    Button: styled.span.attrs(cls.attr('button'))`${ButtonStyles}`,
    Label: styled.span.attrs(cls.attr('label'))<LabelProps>`${LabelStyles}`,
      Title: styled.span`${TitleStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
};

export const SideNavigationLink: Shapeable.FC<SideNavigationLinkProps> = (props) => {
  const { className, children, variant, isActive, entity, onClick, icon, iconSize, sidebarWidth, showActiveSidebar } = props;
  const { Link } = useLink();

  const style = {
    textDecoration: 'none',
    ...(props.style || {}),
  };

  const t = useLang();

  const color = props.color;
  const activeColor = props.activeColor;
  const hoverColor = props.hoverColor;

  const { page, path, name, url } = entity;
  const href = url || path || (page && page.path);

  const hasSidebar = includes(['default'], variant) && showActiveSidebar;
    
  return (
    <My.Container variant={variant} sidebarWidth={hasSidebar && sidebarWidth} isActive={isActive} _color={color} _activeColor={activeColor} _hoverColor={hoverColor} className={cls.name(className)}>
      <Link style={style} href={href}>
        <My.Label variant={variant} isActive={isActive} _color={color} _activeColor={activeColor} _hoverColor={hoverColor}>
          <My.Title>{children || t((page && (page.title || page.name)))}</My.Title>
          {
            icon && 
            <My.Icon size={iconSize}>{icon}</My.Icon>
          }
        </My.Label>
      </Link>
    </My.Container>
  )
};

SideNavigationLink.defaultProps = SideNavigationLinkDefaultProps;
SideNavigationLink.cls = cls;