import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SliceLayoutBoundary, useEntity } from '@shapeable/ui';
import { PublicationsLayout } from '../entities/publications-layout';
import { DARK_BLUE_OVERLAY } from '../../theme';
import { BANNER_KNOWLEDGE_HUB } from '../../data';
import { classNames } from '@shapeable/utils';
import { EntityTitleHeader } from '@shapeable/web';

const cls = classNames('page-layout-publications');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const PublicationsStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Publications: styled(PublicationsLayout)`${PublicationsStyles}`,
};

export const PageLayoutPublications: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader overlayColor={DARK_BLUE_OVERLAY} backgroundImage={BANNER_KNOWLEDGE_HUB} entity={entity} />
      <SliceLayoutBoundary>
        {slices}
        <My.Publications entity={entity} />
        {children}
      </SliceLayoutBoundary>
    </My.Container>
  )
};